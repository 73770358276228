<template>
  <div>
    数据线索
    <div class="pb25 " style="display: flex; justify-content: space-between;">
      <div>
        <el-button type="primary" @click="showDialog" class="pl20"
          >创建任务</el-button
        >
        <el-button type="primary" @click="dialogVisible2 = true"
          >导入</el-button
        >
        <el-link
          style="margin-left: 20px;"
          type="primary"
          href="https://moverse-stand.oss-cn-beijing.aliyuncs.com/default/clue_template.xlsx"
          >点击下载呼叫模板</el-link
        >
      </div>
      <div style="margin-right: 30px;">
        <el-button type="primary" @click="delctDataTrail">删除</el-button>
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="telephone" label="被叫号码" width="200">
        </el-table-column>
        <el-table-column prop="company" label="公司名称" width="200">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="300">
          <template slot-scope="scope">{{
            parseDateString(scope.row.createTime)
          }}</template>
        </el-table-column>
        <el-table-column prop="legalPerson" label="用户名称" width="200">
        </el-table-column>
        <el-table-column prop="companyType" label="行业" width="200">
        </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column
          prop="address"
          label="操作"
          width="160"
          :align="'left'"
        >
          <template slot-scope="scope">
            <i class="el-icon-phone-outline"></i>
            <el-button
              size="mini"
              @click="clickPointCalling(scope.$index, scope.row)"
              type="text"
              >点呼</el-button
            >

            <el-button
              v-if="scope.row.customered == 0"
              size="mini"
              @click="toCustomerClick(scope.row)"
              type="text"
              >转为客户</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="float: right;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        background
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-dialog
        title="创建任务"
        :visible.sync="dialogFormVisible"
        :modal="false"
        center
        width="500px"
        v-if="dialogFormVisible"
      >
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item
            label="任务名称"
            :label-width="formLabelWidth"
            prop="taskName"
          >
            <el-input
              v-model="form.taskName"
              autocomplete="off"
              placeholder="请输入任务名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="呼叫类型"
            :label-width="formLabelWidth"
            prop="callType"
          >
            <el-select v-model="form.callType" placeholder="请选择呼叫类型">
              <el-option label="AI呼叫" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="呼叫间隔"
            :label-width="formLabelWidth"
            prop="callInterval"
          >
            <el-input
              v-model="form.callInterval"
              autocomplete="off"
              placeholder="请输入呼叫间隔（单位：秒）"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label-width="formLabelWidth"
            label="呼叫周期"
            prop="weeks"
          >
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group
              v-model="form.weeks"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                city
              }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="话术模板"
            :label-width="formLabelWidth"
            prop="templateId"
          >
            <el-select
              v-model="form.templateId"
              placeholder="请选择话术模板"
              @change="hdChangetemplateId"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in optionList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >创建任务</el-button
          >
        </div>
        <div></div>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        :visible.sync="dialogVisible2"
        width="30%"
        destroy-on-close
        v-if="dialogVisible2"
      >
        <span>导入文件(允许天眼查导入)</span>
        <el-upload
          drag
          :limit="limitNum"
          :auto-upload="false"
          accept=".xlsx"
          :action="UploadUrl()"
          :before-upload="beforeUploadFile"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileList"
          :before-remove="beforeRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传xlsx文件，且不超过10M
          </div>
        </el-upload>
        <br />
        <el-button size="small" type="primary" @click="uploadFile"
          >立即上传</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getCueData,
  getTemplateList,
  createCustomerTask,
  pointCalling,
  delDataTrail,
  importDataTrail,
  toCustomer,
} from "@/api/aIMarketing";
let base64 = require("js-base64").Base64;
const cityOptions = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
import axios from "axios";
export default {
  data() {
    return {
      currentPage: 1,
      total: 1,
      pageNum: 1,
      pageSize: 10,
      checkAll: false,
      cities: cityOptions,
      isIndeterminate: true,
      tableData: [],
      callDataList: [],
      dialogFormVisible: false,
      dialogVisible2: false,
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      form: {
        callType: "",
        taskName: "",
        templateId: "",
        callInterval: "",
        weeks: ["周一"],
      },
      formLabelWidth: "150px",
      optionList: [],
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        callInterval: [
          { required: true, message: "请输入呼叫间隔", trigger: "blur" },
        ],
        templateId: [
          { required: true, message: "请选择话术模板", trigger: "change" },
        ],
        weeks: [{ required: true, message: "请选择呼叫周期", trigger: "blur" }],
        callType: [
          { required: true, message: "请选择呼叫类型", trigger: "change" },
        ],
      },
      fileTemp: "",
      arr: "",
      imageUrl: "",
      msg: "hello,Element",
      limitUpload: 1,
      file: "",
      fileListUpload: [],
      title: "",
      templateName: "",
      fileList: [], // excel文件列表
      type: "",
    };
  },
  created() {
    this.hdGetCueData();
  },
  methods: {
    // 创建时间转换格式
    parseDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      const parsedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return parsedDate;
    },
    async toCustomerClick(row) {
      var resp = await toCustomer(row);
      if (resp.code == 200) {
        this.$message.success("操作成功");
        this.hdGetCueData();
      }
    },
    hdChangetemplateId(val) {
      // 获取选中的 label 和 value 值
      console.log(val);
      const selectedLabel = this.optionList.find((item) => {
        return item.id == val;
      });
      // console.log("获取选中的 label 和 value 值", selectedLabel.name);
      // const selectedValue = this.form.templateId;

      // console.log("选中的 label:", selectedLabel);
      // console.log("选中的 value:", val);
      this.templateName = selectedLabel.name;
      console.log("获取选中的 label 和 value 值", this.templateName);
    },
    clickPointCalling(index, row) {
      console.log("点呼", index, row);
      // const resata = {
      //   telephone: row.telephone,
      //   fansId: row.fansId,
      // };
      pointCalling({
        telephone: row.telephone,
        fansId: row.fansId,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("点呼成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    submitForm(formName) {
      console.log(this.callDataList);
      if (this.callDataList.length == 0) {
        this.$message({
          title: "警告",
          message: "请选择创建任务的人员",
          type: "warning",
        });
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          console.log({
            ...this.form,
            callDataList: this.callDataList,
            templateName: this.templateName,
          });
          createCustomerTask({
            ...this.form,
            callDataList: this.callDataList,
            templateName: this.templateName,
          })
            .then((res) => {
              console.log("创建成功", res);
              if (res.code == 200) {
                this.$message.success("创建成功");
              } else {
                this.$message.error(res.message);
              }
              this.$refs["ruleForm"].resetFields();
            })
            .catch((err) => {
              this.$message.error(err);
              this.$refs["ruleForm"].resetFields();
            });
        } else {
          this.$message.error(res.message);
          this.$refs["ruleForm"].resetFields();
          return false;
        }
      });
    },
    async hdGetCueData() {
      const res = await getCueData({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code == 200) {
        this.tableData = res.data.pageInfo.list;
        // this.tableData = [res.data.pageInfo.list];
        this.total = res.data.pageInfo.total;
        // console.log("数据", res.data.pageInfo);
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log(val);
      const res = val;
      this.callDataList = res.map((item) => {
        return {
          mobile: item.telephone,
          csname: item.legalPerson,
          fansId: item.dataTrailId,
        };
      });

      console.log("发生改变得数据", this.callDataList);
    },
    //全选全不选
    handleCheckAllChange(val) {
      this.form.weeks = val ? cityOptions : [];
      console.log(this.form.weeks);
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
      console.log(this.form.weeks);
    },
    // 打开创建任务框并请求最新的模板
    async showDialog() {
      const res = await getTemplateList();

      if (res.code == 200) {
        this.optionList = res.data;
        // const list = res.data.map((item) => {
        //   return {
        //     label: item.name,
        //     id: {
        //       id: item.id,
        //       name: item.name,
        //     },
        //   };
        // });
        this.optionList = res.data;
        this.dialogFormVisible = true;
      } else {
        this.$message.error(res.message);
      }
    },

    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      // console.log(this.pageNum);
      this.hdGetCueData();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);

      this.pageNum = val;
      this.hdGetCueData();
    },
    delctDataTrail() {
      console.log(this.callDataList.length);
      if (this.callDataList.length == 0) {
        this.$message.error("请选择要删除的线索");
        return;
      }
      console.log("点击了删除", this.callDataList);
      const dataTrailIds = [];
      this.callDataList.forEach((item) => {
        dataTrailIds.push(item.fansId);
      });
      delDataTrail({
        dataTrailIds,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.hdGetCueData();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    init(params) {
      this.visible = true;
      this.type = params;
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length +
          fileList.length} 个`
      );
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      // 上传文件时触发
      console.log(file.raw);
      this.fileList.push(file.raw);
      console.log(this.fileList);
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      console.log("before upload");
      console.log(file);
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx") {
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 10) {
        this.$message.warning("文件大小不得超过10M");
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$message.success("文件上传成功");
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error("文件上传失败");
    },
    UploadUrl: function() {
      // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
      return "";
    },
    async uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件");
      } else {
        //使用formdata格式传照
        const fromData = new FormData();
        fromData.append("file", this.fileList[0]);
        fromData.append("type", this.type);
        const result = await axios({
          headers: {
            "Content-Type": "multipart/form-data",
          },
          method: "post",
          url: "/api/tkbApi/data-trail/importDataTrail",
          data: fromData,
        });
        if (result.code == 200) {
          this.hdGetCueData();
          this.$message.success(result.message);
          this.dialogVisible2 = false;
          this.fileList.splice(0, 1);
        } else {
          this.$message.error(result.message);
          this.dialogVisible2 = false;
        }
      }
    },
    beforeRemove(file, fileList) {
      this.fileList.splice(0, 1);
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
